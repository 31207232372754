.paginate {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--eds-color-grey-3);
    border-radius: var(--eds-radii-8);
    padding: 4px;
    width: 15rem;
}

.paginate-item, .paginate-next, .paginate-previous {
    width: 26px;
    text-align: center;
    margin: 1px;
    border: 1px solid var(--eds-color-grey-3);
}

.paginate-item-link, .paginate-break-link {
    color: var(--eds-color-teal-80);
}

.paginate-active {
    border-radius: var(--eds-radii-8);
    border: 1px solid var(--eds-color-grey-4);
    background-color: var(--eds-color-grey-4);
}

.paginate-active-link {
    color: var(--eds-color-teal-50);
    font-weight: bold;
}

.paginate svg.eds-icon {
    color: var(--eds-color-teal-80);
    fill: var(--eds-color-teal-80);
}
