.deployment-header {
    font: var(--eds-type-title-4);
}

.deployment-subheader {
    font: var(--eds-type-title-4);
}

.deployment-desc {
    color: #4B5563;
    font: var(--eds-type-title-5)
}

.deployment-result-title {
    font: var(--eds-type-title-5)
}